import request from '@/utils/request'




// 查询用户地区统计
export function areaPageUser(query) {
  return request({
    url: '/dashboard/areaPageUser',
    method: 'get',
    params: query
  })
}
//查询会员地区统计
export function areaPageVip(query) {
  return request({
    url: '/dashboard/areaPageVip',
    method: 'get',
    params: query
  })
}
// 查询会员统计
export function vipPage(data) {
  return request({
    url: '/dashboard/vipPage',
    method: 'get',
    params: data
  })
}

// 查询会员统计
export function vipPageMonth(data) {
  return request({
    url: '/dashboard/vipPageMonth',
    method: 'get',
    params: data
  })
}

// 查询会员订单统计
export function vipOrderPage(data) {
  return request({
    url: '/dashboard/vipOrderPage',
    method: 'get',
    params: data
  })
}

// 查询会员订单统计
export function vipOrderPageMonth(query) {
  return request({
    url: '/dashboard/vipOrderPageMonth',
    method: 'get',
    params: query
  })
}
// 查询会员消费
export function vipMoney(data) {
  return request({
    url: '/dashboard/vipMoney',
    method: 'get',
    params: data
  })
}
